<template>
  <div class="row" v-if="Object.keys( city ).length > 0">

    <div class="col-xl-6 px-0 px-lg-3">
      <div class="card shadow">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center mb-4">
            <div class="card-title">
              <i class="icons8-city-landscape mr-2"></i>
              <span class="mr-3">{{ title }}</span>
            </div>
            <router-link :to="'/cities/' + city.id + '/edit'" class="btn btn-outline-primary">
              Изменить
            </router-link>
          </div>
          <div class="form-group row">
            <label class="col-12 col-xl-4 col-form-label text-muted">Город</label>
            <div class="col-12 col-xl-8">
              <input :value="city.name" type="text" class="form-control-plaintext" readonly>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-xl-4 col-form-label text-muted">Регион</label>
            <div class="col-12 col-xl-8">
              <input :value="city.region.data.name" type="text" class="form-control-plaintext" readonly>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'CityDetail',

    metaInfo() {
      return {
        title: this.title,
      }
    },

    data() {
      return {
        title: '',
        city: {},
      }
    },

    created() {
      this.$wait.start( 'findCityById' )

      this.$api.city.findCityById( this.$route.params.id, 'region' ).then( res => {
        if ( res.status === 200 && res.data.data ) {
          let city = res.data.data

          this.city = city
          this.title = city.name
        }
      } ).finally( () => {
        this.$wait.end( 'findCityById' )
      } )
    },

    beforeRouteEnter( to, from, next ) {
      if ( to.params.id.length !== parseInt( process.env.VUE_APP_HASH_ID_LENGTH ) ) {
        next( vm => {
          vm.$router.push( { name: 'NotFound' } )
        } )
      } else {
        return next()
      }
    },
  }
</script>
